<template>
  <div style="min-height:100vh;padding:.2rem">
    <titleLink title="行程上报" :onClick="onClickBack"></titleLink>
    <card style="margin-top:.4rem">
      <template #content>
        <tourInfoTop :tour="tour"/>
        <van-divider dashed/>
        <van-cell title="游客姓名" :value="user.user.username" />
        <van-cell title="游客手机" :value="user.user.mobile" />
        <fieldForm
          :submit="joinTour"
          :modal="archive_info"
          :fieldData="fields"
          v-on:formUpdate="onFormUpdate"
        ></fieldForm>
      </template>
    </card>
  </div>
</template>

<script>
import fieldForm from '@/views/components/FieldForm'
import {mapGetters} from "vuex"
import api from "@/services/apis"
import card from "@/views/components/Card"
import tourInfoTop from "@/views/components/TourInfoTop"
import {Toast} from "vant"
import {
  validatePhone
} from "@/utils/validators"
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    fieldForm, card, tourInfoTop, titleLink
  },
  data: () => ({
    archive_info: {
      travel_list_id: 0,
      destination_address: "",
      live_address: "",
      mobile: ""
    },
    fields: [
      {
        title: "备案手机",
        name: "mobile",
        type: "text",
        rule: [
          {required: true, message: ""},
          {
            validator: validatePhone,
            message: "请输入正确的电话格式"
          }
        ],
        inline: false,
      },
      {
        title: "入住地址",
        name: "live_address",
        type: "text",
        rule: [{required: true, message: ""}],
        inline: false,
      },
    ]
  }),
  computed: {
    ...mapGetters('wode', {user: 'getPersonInfo'}),
    ...mapGetters('travel_list', {tour: 'currentTravelList'})
  },
  created() {
    const _self = this
    this.archive_info.travel_list_id = this.$route.params.id
    this.$store.dispatch('wode/getPersonInfo').then((personInfo) => {
      _self.archive_info.mobile = personInfo.user.mobile
    })
    this.$store.dispatch('travel_list/getTravelListInfoById', this.$route.params.id).then((res) => {
      _self.archive_info.destination_address = res.data.end_addr
    })
  },
  methods: {
    joinTour() {
      const _self = this
      api.travel_lists_archive_info(this.archive_info).then((res) => {
        if(res.status === 200) {
          api.travel_lists_add_tourist(_self.tour.id).then((res) => {
            if(res.status === 200) {
              Toast('成功加入该行程')
              _self.$store.dispatch('travel_list/getAllTravelLists', '0,1,2,3')
            }
            _self.$router.push('/tour/main')
          })
        }
      })
    },
    onFormUpdate(data) {
      this.archive_info.live_address = data.live_address
    },
    onClickBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>