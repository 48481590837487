export function validatePhone(val) {
  const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return reg.test(val)
}

export function validateNumberAndLetter(val) {
  const reg = /^[a-zA-Z0-9_.-]*$/
  return reg.test(val)
}

export function validateIdCard(val) {
  const reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
  return reg.test(val)
}